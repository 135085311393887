import { Button, Stack, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Select, MenuItem, FormControl, InputLabel, InputAdornment, Typography, Box } from '@mui/material';
import UpIcon from '@mui/icons-material/NorthEastRounded';
import DownIcon from '@mui/icons-material/SouthEastRounded';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAuth } from "../../../auth/contexts/AuthContext";
import budgetGroupPositionService from './api/budgetGroupPositionService';
import POSITION_TYPES from './api/positionTypes';
import BUDGET_GROUPS_QUERY_KEYS from '../budgetGroup/api/budgetGroupsQueryKeys';
import BUDGETS_QUERY_KEYS from '../../budgets/api/budgetsQueryKeys';
import size from '../../../theme/size';

const CreateBudgetGroupPositionDialog = ({ openDialog, setOpenDialog, budgetId, currency, budgetGroupId }) => {
  const authState = useAuth();

  const handleClose = () => {
    formik.resetForm();
    createBudgetGroupPositionMutation.reset();
    setOpenDialog(false);
  };

  const queryClient = useQueryClient();
  const createBudgetGroupPositionMutation = useMutation({
    mutationFn: budgetGroupPositionService.createBudgetGroupPosition,
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [BUDGET_GROUPS_QUERY_KEYS.budgetGroups, +budgetId], exact: true })
      queryClient.invalidateQueries({ queryKey: [BUDGETS_QUERY_KEYS.budgets, +budgetId], exact: true })
      handleClose();
    }
  });

  const handleCreateBudgetGroupPosition = async (values) => {
    const user = await authState.userManager.getUser();

    const price = {
      value: values.value,
      currency: currency
    }

    createBudgetGroupPositionMutation.mutate({
      budgetGroupId: budgetGroupId,
      budgetId: budgetId,
      name: values.name,
      price: price,
      type: values.type,
      accessToken: user.access_token
    });
  };

  const initialValues = {
    name: '',
    type: POSITION_TYPES.EXPENSE,
    value: 0
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      name: Yup
        .string()
        .max(255)
        .required('Name is required'),
      type: Yup
        .string()
        .max(10)
        .required('Type is required'),
      value: Yup
        .number()
        .min(0, 'Value must be greater than or equal to 0')
        .required('Value is required')
    }),
    onSubmit: async (values, helpers) => {
      try {
        handleCreateBudgetGroupPosition(values);
      }
      catch (error) {
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);

        helpers.setErrors({ submit: error.message });
      }
    }
  });

  const onKeyDown = (event) => {
    if (event.key === '-' || event.key === '+' || event.key === 'e') {
      event.preventDefault();
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" justifyContent="center" sx={{ p: 1, mr: 1, ml: 1, minWidth: size.dialogWidth }}>
          <DialogTitle sx={{ mt: 1 }}>
            {"Create expense or income"}
          </DialogTitle>
          <DialogContent>
            <Stack direction="column" justifyContent="center" spacing={3} sx={{ mt: 3 }}>
              <TextField
                error={!!(formik.touched.name && formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                label="Name"
                name="name"
                onChange={formik.handleChange}
                type="text"
                value={formik.values.name}
              />
              <FormControl variant="filled">
                <InputLabel id="position-type=select">Type</InputLabel>
                <Select
                  labelId="position-type=select"
                  label="Type"
                  name="type"
                  onChange={formik.handleChange}
                  value={formik.values.type} >
                  <MenuItem value={POSITION_TYPES.EXPENSE}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }} >
                      <Typography variant="subtitle2">
                        {POSITION_TYPES.EXPENSE}
                      </Typography>
                      <DownIcon fontSize="small" color="error" />
                    </Box>
                  </MenuItem>
                  <MenuItem value={POSITION_TYPES.INCOME}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }} >
                      <Typography variant="subtitle2">
                        {POSITION_TYPES.INCOME}
                      </Typography>
                      <UpIcon fontSize="small" color="green" />
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                inputProps={{
                  step: 0.01,
                  min: 0,
                  type: 'number',
                  inputMode: 'decimal'
                }}
                error={!!formik.errors.value}
                helperText={formik.errors.value}
                label="Value"
                name="value"
                onKeyDown={onKeyDown}
                onChange={formik.handleChange}
                value={formik.values.value}
                InputProps={{
                  endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                }}
              />
              {formik.errors.submit && (
                <DialogContentText
                  color="error"
                  sx={{ mt: 3 }}
                  variant="body2"
                >
                  {formik.errors.submit}
                </DialogContentText>
              )}
              {createBudgetGroupPositionMutation.isError && (
                <DialogContentText
                  color="error"
                  sx={{ mt: '8px' }}
                  variant="body2" >
                  {createBudgetGroupPositionMutation.error.response
                    ? <b>{createBudgetGroupPositionMutation.error.response.data.message}</b>
                    : <b>{createBudgetGroupPositionMutation.error.message}</b>}
                </DialogContentText>
              )}
            </Stack>
          </DialogContent>
        </Stack>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={createBudgetGroupPositionMutation.isPending} type="submit" variant="contained">
            {createBudgetGroupPositionMutation.isPending ? "Creating..." : "Create"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CreateBudgetGroupPositionDialog;