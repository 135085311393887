import { Button, Stack, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { parseISO } from 'date-fns';

import BUDGETS_QUERY_KEYS from "./api/budgetsQueryKeys";
import { useAuth } from "../../auth/contexts/AuthContext";
import dateValidator from '../../common/date/dateValidator';
import size from '../../theme/size';
import budgetService from '../budget/api/budgetService';

const UpdateBudgetDialog = ({ openDialog, setOpenDialog, id, name, startDate, endDate }) => {
  const authState = useAuth();

  const handleClose = () => {
    formik.resetForm();
    updateBudgetMutation.reset();
    setOpenDialog(false);
  };

  const handleCloseAfterUpdate = () => {
    updateBudgetMutation.reset();
    setOpenDialog(false);
  }

  const queryClient = useQueryClient();
  const updateBudgetMutation = useMutation({
    mutationFn: budgetService.updateBudget,
    onSuccess: data => {
      queryClient.invalidateQueries({queryKey: [BUDGETS_QUERY_KEYS.budgets, +data.id], exact: true});
      handleCloseAfterUpdate();
    }
  });

  const handleUpdateBudget = async (values) => {
    const user = await authState.userManager.getUser();

    updateBudgetMutation.mutate({
      id: id,
      name: values.name,
      startDate: values.startDate,
      endDate: values.endDate,
      accessToken: user.access_token
    });
  };

  const initialValues = {
    name: name,
    startDate: startDate ? parseISO(startDate) : null,
    endDate: endDate ? parseISO(endDate) : null
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      name: Yup
        .string()
        .max(255)
        .required('Name is required'),
      startDate: Yup
        .date()
        .optional()
        .nullable(),
      endDate: Yup
        .date()
        .optional()
        .nullable()
        .when('startDate', {
          is: (startDate) => dateValidator.isValid(startDate),
          then: (schema) => schema.min(Yup.ref('startDate'), 'End date cannot be earlier than start date'),
          otherwise: (schema) => schema
        })
    }),
    onSubmit: async (values, helpers) => {
      try {
        handleUpdateBudget(values);
      }
      catch (error) {
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);

        helpers.setErrors({ submit: error.message });
      }
    }
  });

  const handleFormikDateErrors = (date, error) => {
    if (!error) {
      return error;
    }

    if (!dateValidator.isValid(date)) {
      return "Specify valid date";
    }

    return error;
  }

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" justifyContent="center" sx={{ p: 1, mr: 1, ml: 1, minWidth: size.dialogWidth }}>
          <DialogTitle sx={{ mt: 1 }}>
            {'Update budget'}
          </DialogTitle>
          <DialogContent>
            <Stack direction="column" justifyContent="center" spacing={3} sx={{ mt: 3 }}>
              <TextField
                error={!!(formik.touched.name && formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                label="Name"
                name="name"
                onChange={formik.handleChange}
                type="text"
                value={formik.values.name}
              />
              <DatePicker
                label="Start date"
                name="startDate"
                onChange={value => formik.setFieldValue('startDate', value)}
                value={formik.values.startDate}
                slotProps={{
                  field: { clearable: true },
                  actionBar: {
                    actions: ['cancel', 'clear', 'accept']
                  },
                  textField: {
                    helperText: handleFormikDateErrors(formik.values.startDate, formik.errors.startDate),
                  }
                }}
              />
              <DatePicker
                label="End date"
                name="endDate"
                onChange={value => formik.setFieldValue('endDate', value)}
                value={formik.values.endDate}
                minDate={formik.values.startDate}
                slotProps={{
                  field: { clearable: true },
                  actionBar: {
                    actions: ['cancel', 'clear', 'accept']
                  },
                  textField: {
                    helperText: handleFormikDateErrors(formik.values.endDate, formik.errors.endDate),
                  }
                }}
              />
              {formik.errors.submit && (
                <DialogContentText
                  color="error"
                  sx={{ mt: 3 }}
                  variant="body2"
                >
                  {formik.errors.submit}
                </DialogContentText>
              )}
              {updateBudgetMutation.isError && (
                <DialogContentText
                  color="error"
                  sx={{ mt: '8px' }}
                  variant="body2" >
                  {updateBudgetMutation.error.response
                    ? <b>{updateBudgetMutation.error.response.data.message}</b>
                    : <b>{updateBudgetMutation.error.message}</b>}
                </DialogContentText>
              )}
            </Stack>
          </DialogContent>
        </Stack>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={updateBudgetMutation.isPending} type="submit" variant="contained">
            {updateBudgetMutation.isPending ? "Updating..." : "Update"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default UpdateBudgetDialog;