import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enGB from 'date-fns/locale/en-GB';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { createTheme } from './theme/index';
import Routing from './routing/Routing';
import { UserProvider } from './auth/contexts/UserContext';
import { AuthProvider } from './auth/contexts/AuthContext';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

function App() {
  const theme = createTheme();
  const queryClient = new QueryClient();

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <UserProvider>
                <Routing />
              </UserProvider>
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
