import { Typography, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

import CURRENCY from './Currency';

const CurrencySelect = ({currencyPropertyName, labelName, formik, disabled, sx}) => {
  return (
    <FormControl
      variant="filled"
      onBlur={formik.handleBlur(currencyPropertyName)}
      onChange={formik.handleChange(currencyPropertyName)}
      onClick={() => formik.setFieldTouched(currencyPropertyName)}
      error={!!(formik.touched[currencyPropertyName] && formik.errors[currencyPropertyName])}
      sx={sx}>
      <InputLabel id="currency-select-label">{labelName}</InputLabel>
      <Select
        labelId="currency-select-label"
        label={labelName}
        name={currencyPropertyName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values[currencyPropertyName]}
        disabled={disabled}>
        <MenuItem value={CURRENCY.PLN}>
          <Typography variant="subtitle2">
            {CURRENCY.PLN}
          </Typography>
        </MenuItem>
        <MenuItem value={CURRENCY.EUR}>
          <Typography variant="subtitle2">
            {CURRENCY.EUR}
          </Typography>
        </MenuItem>
        <MenuItem value={CURRENCY.USD}>
          <Typography variant="subtitle2">
            {CURRENCY.USD}
          </Typography>
        </MenuItem>
        <MenuItem value={CURRENCY.GBP}>
          <Typography variant="subtitle2">
            {CURRENCY.GBP}
          </Typography>
        </MenuItem>
      </Select>
      {!!(formik.touched[currencyPropertyName] && formik.errors[currencyPropertyName]) &&
        <FormHelperText>
          {formik.errors[currencyPropertyName]}
        </FormHelperText>
      }
    </FormControl>
  );
}

export default CurrencySelect;