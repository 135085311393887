import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, Stack, TextField, CircularProgress } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useAuth } from "../../auth/contexts/AuthContext";

import BUDGET_GROUPS_QUERY_KEYS from "../budget/budgetGroup/api/budgetGroupsQueryKeys";
import BUDGETS_QUERY_KEYS from "./api/budgetsQueryKeys";
import size from "../../theme/size";
import CurrencySelect from "../../common/currency/CurrencySelect";
import budgetService from "../budget/api/budgetService";
import CURRENCY_QUERY_KEYS from "../../common/currency/api/currencyQueryKeys";
import currencyService from "../../common/currency/api/currencyService";
import { useEffect } from "react";

const ChangeCurrencyDialog = ({ openDialog, setOpenDialog, id, baseSaldo, baseCurrency }) => {
  const authState = useAuth();
  const queryClient = useQueryClient();

  const onKeyDown = (event) => {
    if (event.key === '-' || event.key === '+' || event.key === 'e') {
      event.preventDefault();
    }
  };

  const handleClose = () => {
    formik.resetForm();
    changeCurrencyMutation.reset();
    setOpenDialog(false);
  };

  const handleCloseAfterUpdate = () => {
    changeCurrencyMutation.reset();
    setOpenDialog(false);
  }

  const defaultCurrencyQuery = useQuery({
    queryKey: [CURRENCY_QUERY_KEYS.defaultCurrency],
    queryFn: () => getDefaultCurrency(),
    enabled: false,
  });

  const rateQuery = useQuery({
    queryKey: [CURRENCY_QUERY_KEYS.rate],
    queryFn: () => getRate(),
    enabled: false
  });

  const targetSaldoQuery = useQuery({
    queryKey: [BUDGETS_QUERY_KEYS.budgets, BUDGETS_QUERY_KEYS.exchanged, +id],
    queryFn: () => getTargetSaldo(),
    enabled: false
  });

  const initialValues = {
    baseSaldo: baseSaldo,
    baseCurrency: baseCurrency,
    targetSaldo: baseSaldo,
    targetCurrency: defaultCurrencyQuery.isSuccess ? defaultCurrencyQuery.data.defaultCurrency : '',
    rate: rateQuery.isSuccess ? rateQuery.data.rate : 1,
  };

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: initialValues,
    validationSchema: Yup.object({
      baseSaldo: Yup
        .number()
        .required('Base saldo is required'),
      baseCurrency: Yup
        .string()
        .max(3)
        .required('Base currency is required'),
      targetSaldo: Yup
        .number()
        .required('Target saldo is required'),
      targetCurrency: Yup
        .string()
        .max(3)
        .required('Target currency is required'),
      rate: Yup
        .number()
        .moreThan(0, 'Rate must be greater than 0')
        .required('Rate is required')
    }),
    onSubmit: async (values, helpers) => {
      try {
        handleChangeCurrency(values);
      }
      catch (error) {
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);

        helpers.setErrors({ submit: error.message });
      }
    }
  });

  const getDefaultCurrency = async () => {
    const user = await authState.userManager.getUser();
    const response = await currencyService.getDefaultCurrency(user.access_token);

    return response.data;
  }

  useEffect(() => {
    async function fetchDefaultCurrency(){
      if(!openDialog){
        return;
      }

      await defaultCurrencyQuery.refetch();
    }
    fetchDefaultCurrency();
  }, [openDialog]);

  useEffect(() => {
    async function refreshDefaultCurrencyValue() {
      if(!openDialog || !defaultCurrencyQuery.data?.defaultCurrency){
        return;
      }

      await formik.setFieldValue('targetCurrency', defaultCurrencyQuery.data.defaultCurrency);
    }
    refreshDefaultCurrencyValue();
  }, [defaultCurrencyQuery.data?.defaultCurrency]);

  const getRate = async () => {
    const user = await authState.userManager.getUser();
    const rateResponse = await currencyService.getRate(formik.values.baseCurrency, formik.values.targetCurrency, user.access_token);

    return rateResponse.data;
  };

  useEffect(() => {
    async function refreshRate() {
      if(!openDialog || !formik.values.baseCurrency || !formik.values.targetCurrency){
        return;
      }

      await rateQuery.refetch();
    }
    refreshRate();
  }, [formik.values.targetCurrency]);

  useEffect(() => {
    async function refreshRateValue() {
      if(!openDialog || !rateQuery.data?.rate){
        return;
      }

      await formik.setFieldValue('rate', rateQuery.data.rate);
    }
    refreshRateValue();
  }, [rateQuery.data?.rate]);

  const getTargetSaldo = async () => {
    const user = await authState.userManager.getUser();
    
    const targetSaldoResponse = await budgetService.getExchangedBudgetSaldo(id, formik.values.targetCurrency, formik.values.rate, user.access_token);

    return targetSaldoResponse.data;
  };

  useEffect(() => {
    async function refreshTargetSaldo() {
      if(!openDialog || !formik.values.targetCurrency || !formik.values.rate){
        return;
      }

      await targetSaldoQuery.refetch();
    }
    refreshTargetSaldo();
  }, [formik.values.rate]);

  useEffect(() => {
    async function refreshTargedSaldoValdo() {
      if(!openDialog || !targetSaldoQuery.data?.value){
        return;
      }

      await formik.setFieldValue('targetSaldo', targetSaldoQuery.data?.value);
    }
    refreshTargedSaldoValdo();
  }, [targetSaldoQuery.data?.value]);

  const changeCurrencyMutation = useMutation({
    mutationFn: budgetService.exchangeBudget,
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [BUDGET_GROUPS_QUERY_KEYS.budgetGroups, +id], exact: true })
      queryClient.invalidateQueries({ queryKey: [BUDGETS_QUERY_KEYS.budgets, +id], exact: true })
      handleCloseAfterUpdate();
    }
  });

  const handleChangeCurrency = async (values) => {
    const user = await authState.userManager.getUser();

    changeCurrencyMutation.mutate({
      id: id,
      targetCurrency: values.targetCurrency,
      rate: values.rate,
      accessToken: user.access_token
    });
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" justifyContent="center" sx={{ p: 1, mr: 1, ml: 1, minWidth: size.dialogWidth }}>
          <DialogTitle sx={{ mt: 1 }}>
            {'Change currency'}
          </DialogTitle>
          <DialogContent>
            <Stack direction="column" justifyContent="center" spacing={3} sx={{ mt: 3 }}>
              <Box sx={{ display: 'flex', gap: '8px' }}>
                <TextField
                  sx={{ flex: 2 }}
                  type="number"
                  error={!!formik.errors.baseSaldo}
                  helperText={formik.errors.baseSaldo}
                  label="Saldo"
                  name="baseSaldo"
                  value={formik.values.baseSaldo}
                  disabled={true}
                />
                <TextField
                  sx={{ flex: 1 }}
                  type="text"
                  error={!!formik.errors.baseCurrency}
                  helperText={formik.errors.baseCurrency}
                  label="Currency"
                  name="baseCurrency"
                  value={formik.values.baseCurrency}
                  disabled={true}
                />
              </Box>
              <TextField
                error={!!formik.errors.rate}
                helperText={formik.errors.rate}
                label="Rate"
                name="rate"
                onKeyDown={onKeyDown}
                inputProps={{
                  step: 0.0001,
                  min: 0.0001,
                  type: 'number',
                  inputMode: 'decimal'
                }}
                onChange={formik.handleChange}
                value={formik.values.rate}
                InputProps={{
                  startAdornment: <InputAdornment position="start" sx={{marginBottom: '0.5px'}}>x</InputAdornment>,
                  endAdornment: rateQuery.isFetching ? <InputAdornment> <CircularProgress size="24px"/></InputAdornment> : <></>
                }}
              />
              <Box sx={{ display: 'flex', gap: '8px' }}>
                <TextField
                  sx={{ flex: 2 }}
                  type="number"
                  error={!!formik.errors.value}
                  helperText={formik.errors.value}
                  label="Saldo"
                  name="targetSaldo"
                  value={formik.values.targetSaldo}
                  disabled={true}
                  InputProps={{
                    endAdornment: targetSaldoQuery.isFetching ? <InputAdornment> <CircularProgress size="24px"/></InputAdornment> : <></>
                  }}
                />
                <CurrencySelect
                  sx={{ flex: 1 }}
                  currencyPropertyName={'targetCurrency'}
                  labelName={'Currency'}
                  formik={formik}/>
              </Box>
              {formik.errors.submit && (
                <DialogContentText
                  color="error"
                  sx={{ mt: 3 }}
                  variant="body2"
                >
                  {formik.errors.submit}
                </DialogContentText>
              )}
              {rateQuery.isError && (
                <DialogContentText
                  color="error"
                  sx={{ mt: '8px' }}
                  variant="body2" >
                  {rateQuery.error.response
                    ? <b>{rateQuery.error.response.data.message}</b>
                    : <b>{rateQuery.error.message}</b>}
                </DialogContentText>
              )}
              {targetSaldoQuery.isError && (
                <DialogContentText
                  color="error"
                  sx={{ mt: '8px' }}
                  variant="body2" >
                  {targetSaldoQuery.error.response
                    ? <b>{targetSaldoQuery.error.response.data.message}</b>
                    : <b>{targetSaldoQuery.error.message}</b>}
                </DialogContentText>
              )}
              {changeCurrencyMutation.isError && (
                <DialogContentText
                  color="error"
                  sx={{ mt: '8px' }}
                  variant="body2" >
                  {changeCurrencyMutation.error.response
                    ? <b>{changeCurrencyMutation.error.response.data.message}</b>
                    : <b>{changeCurrencyMutation.error.message}</b>}
                </DialogContentText>
              )}
            </Stack>
          </DialogContent>
        </Stack>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={changeCurrencyMutation.isPending} type="submit" variant="contained">
            {changeCurrencyMutation.isPending ? "Exchanging..." : "Exchange"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ChangeCurrencyDialog;