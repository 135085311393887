import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAuth } from "../../auth/contexts/AuthContext";
import BUDGETS_QUERY_KEYS from './api/budgetsQueryKeys';
import { useNavigate } from 'react-router-dom';
import budgetService from '../budget/api/budgetService';

const DeleteBudgetDialog = ({ openDialog, setOpenDialog, navigatePath, id, name }) => {
  const authState = useAuth();
  const navigate = useNavigate();

  const handleClose = () => {
    deleteBudgetMutation.reset();
    setOpenDialog(false);
  };

  const handleCloseAfterDelete = () => {
    handleClose();
    if(navigatePath){
      navigate(navigatePath);
    }
  }

  const queryClient = useQueryClient();
  const deleteBudgetMutation = useMutation({
    mutationFn: budgetService.deleteBudget,
    onSuccess: data => {
      queryClient.invalidateQueries({queryKey: [BUDGETS_QUERY_KEYS.budgets], exact: true})
      handleCloseAfterDelete();
    },
  });

  const handleDeleteBudget = async () => {
    const user = await authState.userManager.getUser();

    deleteBudgetMutation.mutate({
      id: id,
      accessToken: user.access_token
    });
  };

  return (
    <Dialog
        open={openDialog}
        onClose={handleClose}
      >
        <DialogTitle>
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Budget <b>{name}</b> will be removed with all content
          </DialogContentText>
          {deleteBudgetMutation.isError && (
            <DialogContentText
              color="error"
              sx={{ mt: '8px' }}
              variant="body2" >
              {deleteBudgetMutation.error.response
                ? <b>{deleteBudgetMutation.error.response.data.message}</b> 
                : <b>{deleteBudgetMutation.error.message}</b>}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDeleteBudget} disabled={deleteBudgetMutation.isPending} variant="contained">
          {deleteBudgetMutation.isPending ? "Deleting..." : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default DeleteBudgetDialog;