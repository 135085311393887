import { useNavigate } from 'react-router-dom';

import { Box, Button, Link, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import AuthLayout from './AuthLayout';
import PasswordField from '../common/PasswordField';
import authService from './api/authService';
import apiService from './api/apiService';
import PATH from '../routing/routingPaths';
import CURRENCY from '../common/currency/Currency';
import CurrencySelect from '../common/currency/CurrencySelect';

const Register = () => {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      confirmedPassword: '',
      currency: CURRENCY.PLN
    },
    validationSchema: Yup.object({
      username: Yup
        .string()
        .max(255)
        .required('Username is required'),
      password: Yup
        .string()
        .max(255)
        .required('Password is required'),
      confirmedPassword: Yup
        .string()
        .max(255)
        .required('Password confirmation is required')
        .oneOf([Yup.ref('password')], 'Your passwords are not equal'),
      currency: Yup
        .string()
        .max(3)
        .required('Currency is required')
    }),
    onSubmit: async (values, helpers) => {
      try {
        await apiService.register(values.username, values.password, values.confirmedPassword, values.currency);
        await authService.login(values.username, values.password);

        navigate(`${PATH.root}${PATH.authorize}`);
      } catch (error) {
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);

        if (error.response) {
          helpers.setErrors({ submit: error.response.data.message });
        }
        else {
          helpers.setErrors({ submit: error.message });
        }
      }
    }
  });

  return (
    <AuthLayout>
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '32px', marginBottom: '32px' }}>
        <Box sx={{ flex: lgUp ? 0.5 : 0.75, display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography variant={lgUp ? "h4" : "h5"}>
            Register
          </Typography>
          <Typography color="text.secondary" variant="body2">
            Already have an account?&nbsp;
            <Link
              style={{ cursor: 'pointer' }}
              underline="hover"
              variant="subtitle2"
              onClick={() => navigate(`${PATH.root}${PATH.login}`)}
            >
              Login
            </Link>
          </Typography>
          <form
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <Stack spacing={3}>
              <TextField
                error={!!(formik.touched.username && formik.errors.username)}
                fullWidth
                helperText={formik.touched.username && formik.errors.username}
                label="Username"
                name="username"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.username}
              />
              <PasswordField formik={formik} formikValue={'password'} label={'Password'} />
              <PasswordField formik={formik} formikValue={'confirmedPassword'} label={'Password (Confirm)'} />
              <CurrencySelect currencyPropertyName={'currency'} labelName={'Currency'} formik={formik} disabled={false} />
            </Stack>
            {formik.errors.submit && (
              <Typography
                color="error"
                sx={{ mt: 3 }}
                variant="body2"
              >
                {formik.errors.submit}
              </Typography>
            )}
            <Button
              fullWidth
              size="large"
              sx={{ mt: 3 }}
              type="submit"
              variant="contained"
            >
              Continue
            </Button>
          </form>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default Register;
