import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URI;

const budgetService = {
  getUserBudget: async function (id, accessToken) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };

    return await axios.get(`${baseUrl}Budgets/GetUserBudget/${id}`, config);
  },
  getExchangedBudgetSaldo: async function (id, targetCurrency, rate, accessToken){
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };

    return await axios.get(`${baseUrl}Budgets/GetExchangedBudgetSaldo?BudgetId=${id}&TargetCurrency=${targetCurrency}&Rate=${rate}`, config);
  },
  createBudget: async function (values) {
    const config = {
      headers: { Authorization: `Bearer ${values.accessToken}` }
    };

    var response = await axios.post(`${baseUrl}Budgets/CreateBudget`, {
      name: values.name,
      currency: values.currency,
      startDate: values.startDate,
      endDate: values.endDate
    }, config);

    return response.data;
  },
  updateBudget: async function (values) {
    const config = {
      headers: { Authorization: `Bearer ${values.accessToken}` }
    };

    var response = await axios.put(`${baseUrl}Budgets/UpdateBudget`, {
      id: values.id,
      name: values.name,
      startDate: values.startDate,
      endDate: values.endDate
    }, config);

    return response.data;
  },
  deleteBudget: async function (values) {
    const config = {
      headers: { Authorization: `Bearer ${values.accessToken}` }
    };

    var response = await axios.delete(`${baseUrl}Budgets/DeleteBudget/${values.id}`, config);

    return response.data;
  },
  exchangeBudget: async function (values) {
    const config = {
      headers: { Authorization: `Bearer ${values.accessToken}` }
    };

    var response = await axios.put(`${baseUrl}Budgets/ExchangeBudget`, {
      budgetId: values.id,
      targetCurrency: values.targetCurrency,
      rate: values.rate
    }, config);

    return response.data;
  }
}

export default budgetService;